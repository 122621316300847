<template>
  <div class="carousel-page">
    <template v-if="mode == 'common'">
      <div class="banner-one hidden-xs">
        <ul class="banner-one-nav">
          <li @click="switchBanner(0)" class="active">优制云CRM</li>
          <li @click="switchBanner(1)">优制云MES</li>
          <li @click="switchBanner(2)">优制云ERP</li>
          <li @click="switchBanner(3)">伟班APP</li>
        </ul>
        <swiper ref="mySwiperOne" :options="swiperOptionsOne">
          <swiper-slide class="swiper-no-swiping">
            <AdvSpace
              :src="require('@/assets/huaihua.jpg')"
              title="优制云CRM"
              txtarea="优制云CRM以量化的、基于过程的科学方法，分阶段管理销售过程中的所有活动(市场活动、线索管理、客户管理、商机管理、合同回款、实施交付及二次销售等)，帮助企业及时了解客户在不同销售阶段的不同需求，提供个性化的服务，提升每个销售阶段的销售机会，建立客户价值体系，并挖掘客户的潜在价值，整体提高销售，最终实现企业利润和客户价值的双重最大化。"
            />
          </swiper-slide>
          <swiper-slide class="swiper-no-swiping">
            <AdvSpace
              :src="require('@/assets/huaihua.jpg')"
              title="优制云MES"
              txtarea="用数据和算法帮助企业缩短生产周期，降低库存积压，提升产能利用率，透明化制造流程，实现数据驱动化的精益制造。"
            />
          </swiper-slide>
          <swiper-slide class="swiper-no-swiping">
            <AdvSpace
              :src="require('@/assets/huaihua.jpg')"
              title="优制云ERP"
              txtarea="以客户为中心，以产品创新为核心，实现从产品设计到生产制造、销售管理到售后服务的整个产供销全生命周期的一体化管理。"
            />
          </swiper-slide>
          <swiper-slide class="swiper-no-swiping">
            <AdvSpace
              :src="require('@/assets/huaihua.jpg')"
              title="伟班APP"
              txtarea="让企业管理者、员工随时随地进行移动协同办公，帮助企业降低沟通、协同、管理成本，提升办公、运营效率，实现数字化新工作方式。"
            />
          </swiper-slide>
        </swiper>
      </div>
      <!-- 移动端 -->
      <div class="banner-two visible-xs-block">
        <swiper id="mySwiperTwo" ref="mySwiperTwo" :options="swiperOptionsTwo">
          <swiper-slide>
            <div class="title" data-swiper-parallax="-600">优制云CRM</div>
            <div class="subtitle" data-swiper-parallax="-500">Subtitle</div>
            <p class="text" data-swiper-parallax="-300">
              优制云CRM以量化的、基于过程的科学方法，分阶段管理销售过程中的所有活动(市场活动、线索管理、客户管理、商机管理、合同回款、实施交付及二次销售等)，帮助企业及时了解客户在不同销售阶段的不同需求，提供个性化的服务，提升每个销售阶段的销售机会，建立客户价值体系，并挖掘客户的潜在价值，整体提高销售，最终实现企业利润和客户价值的双重最大化。
            </p>
            <button
              class="btn btn-default bottomDetail"
              type="submit"
              @click="toRegister"
            >
              立即体验
            </button>
          </swiper-slide>
          <swiper-slide>
            <div class="title" data-swiper-parallax="-600">优制云MES</div>
            <div class="subtitle" data-swiper-parallax="-200">Subtitle</div>
            <p class="text" data-swiper-parallax="-300">
              用数据和算法帮助企业缩短生产周期，降低库存积压，提升产能利用率，透明化制造流程，实现数据驱动化的精益制造。
            </p>
            <button
              class="btn btn-default bottomDetail"
              type="submit"
              @click="toRegister"
            >
              立即体验
            </button>
          </swiper-slide>
          <swiper-slide>
            <div class="title" data-swiper-parallax="-600">优制云ERP</div>
            <div class="subtitle" data-swiper-parallax="-200">Subtitle</div>
            <p class="text" data-swiper-parallax="-300">
              以客户为中心，以产品创新为核心，实现从产品设计到生产制造、销售管理到售后服务的整个产供销全生命周期的一体化管理。
            </p>
            <button
              class="btn btn-default bottomDetail"
              type="submit"
              @click="toRegister"
            >
              立即体验
            </button>
          </swiper-slide>
          <swiper-slide>
            <div class="title" data-swiper-parallax="-600">伟班APP</div>
            <div class="subtitle" data-swiper-parallax="-200">Subtitle</div>
            <p class="text" data-swiper-parallax="-300">
              让企业管理者、员工随时随地进行移动协同办公，帮助企业降低沟通、协同、管理成本，提升办公、运营效率，实现数
              字化新工作方式。
            </p>
            <button
              class="btn btn-default bottomDetail"
              type="submit"
              @click="toRegister"
            >
              立即体验
            </button>
          </swiper-slide>
        </swiper>
      </div>
    </template>
    <template v-else>
      <swiper
        ref="mySwiperThree"
        :options="swiperOptionsThree"
        class="banner-three"
      >
        <swiper-slide class="swiper-no-swiping">
          <div class="member-info hoverStyle" @click.stop="pageJump(1)">
            <div class="img-box">
              <img :src="require('@/assets/member/xzp.png')" alt="商会成员" />
            </div>
            <p>向振鹏</p>
            <p>广东省湖南怀化商会 副会长</p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-no-swiping">
          <div class="member-info hoverStyle" @click.stop="pageJump(2)">
            <div class="img-box">
              <img :src="require('@/assets/member/lap.png')" alt="商会成员" />
            </div>
            <p>龙安平</p>
            <p>广东省湖南怀化商会 理事</p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-no-swiping">
          <div class="member-info hoverStyle" @click.stop="pageJump(3)">
            <div class="img-box">
              <img :src="require('@/assets/member/tlz.png')" alt="商会成员" />
            </div>
            <p>谭灵芝</p>
            <p>广东省湖南怀化商会 理事</p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-no-swiping">
          <div class="member-info hoverStyle" @click.stop="pageJump(4)">
            <div class="img-box">
              <img :src="require('@/assets/member/xxh.png')" alt="商会成员" />
            </div>
            <p>肖晓红</p>
            <p>广东省湖南怀化商会 副会长</p>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-no-swiping">
          <div class="member-info hoverStyle" @click.stop="pageJump(5)">
            <div class="img-box">
              <img :src="require('@/assets/member/ws.png')" alt="商会成员" />
            </div>
            <p>王珊</p>
            <p>广东省怀化商会 常务副会长</p>
          </div>
        </swiper-slide>
      </swiper>
    </template>
  </div>
</template>
<script>
import AdvSpace from "../../components/AdvertisingSpace.vue";
import "swiper/swiper-bundle.min.css"; //导入样式
import { Swiper, SwiperSlide } from "vue-awesome-swiper"; //使用Swiper6包装好的组件
import SwiperCore, { Autoplay, Pagination } from "swiper"; //使用swiper6的模组扩展
SwiperCore.use([Autoplay, Pagination]); //注册模块

export default {
  props: {
    mode: {
      type: String,
      default: "common", //common-常规模式 ；centered-多slide
    },
    swiperType: {
      type: String,
      default: "horizontal", // horizontal-水平  vertical-垂直
    },
    arr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      viewNum: 5,
      bgColor: [
        "rgb(179, 189, 196)",
        "rgb(180, 183, 166)",
        "rgb(140, 152, 187)",
      ],
      swiperOptionsOne: {
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      },
      swiperOptionsTwo: {
        height: 100,
        speed: 600,
        parallax: true,
      },
      swiperOptionsThree: {
        loop: true,
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 3,
        spaceBetween: 1,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    AdvSpace,
  },
  computed: {
    mySwiperOne() {
      return this.$refs.mySwiperOne.$swiper;
    },
    mySwiperTwo() {
      return this.$refs.mySwiperTwo.$swiper;
    },
    mySwiperThree() {
      return this.$refs.mySwiperThree.$swiper;
    },
  },
  mounted() {},
  methods: {
    pageJump(index) {
      // let name_="UnitIntroduce"+index;
      // this.$router.push({
      //   name: name_,
      // });
    },
    switchBanner(index) {
      let e = $(".banner-one-nav").find("li")[index];
      $(e).siblings().removeClass("active");
      $(e).addClass("active");
      this.mySwiperOne.slideTo(index, 1000, false);
    },
    //不使用自带的按钮组件，使用lock标记按钮是否被锁定
    slideNext() {
      debugger;
      if (!this.lock) {
        this.swiperLg.slideNext();
      }
    },
    slidePrev() {
      if (!this.lock) {
        this.swiperLg.slidePrev();
      }
    },
    toRegister() {
      this.$router.push({
        name: "Register",
      });
    },
  },
  watch: {
    // screenWidth(val){
    //   // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    //   if(!this.timer){
    //       // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
    //       this.screenWidth = val
    //       debugger
    //       if(this.screenWidth<=768){
    //         this.viewNum=3;
    //       }else{
    //         this.viewNum=5;
    //       }
    //       // this.mySwiperThree.init();
    //       this.mySwiperThree.destroy()   //先销毁
    //       this.mySwiperThree.mountInstance()   //后在加载
    //       this.timer = true
    //       let that = this
    //       setTimeout(function(){
    //           // 打印screenWidth变化的值
    //           console.log(that.screenWidth)
    //           that.timer = false
    //       },400)
    //   }
    // }
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 200px) and (max-width: 768px) {
  .banner-three {
    margin: 40px 0 25px 0;
    height: 300px;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .member-info {
      width: 190px;
      height: 260px;
      border-radius: 3px;
      overflow: hidden;
      padding-bottom: 10px;
      box-shadow: 10px 7px 12px 0px #8888885e;
      .img-box {
        width: 100%;
        height: 126px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      & > p:nth-of-type(1) {
        font-size: 18px;
        margin: 6px 5px;
        font-weight: 700;
        font-family: serif;
      }
      & > p:nth-of-type(2) {
        font-size: 16px;
        margin: 4px 5px;
      }
    }
  }
}

@import "../../styles/index";
.carousel-page {
  width: 100%;
}
.banner-one {
  height: auto;
  box-sizing: border-box;
  padding: 5px 0;
  .banner-one-nav {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    li {
      display: flex;
      height: 50px;
      width: 25%;
      font-size: 20px;
      color: #8d8d8d;
      background: #fff;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: #d89a60b9;
      }
    }
    .active {
      color: #d89a60b9;
      font-weight: 600;
      border-bottom: 3px #d89a60b9 solid;
    }
  }
}
.banner-two {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  width: 88vw;
  margin: auto;
  /deep/.swiper-container {
    position: relative;
    height: 100%;
    .parallax-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 130%;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-image: url("../../assets/huaihua.jpg");
    }
    .swiper-slide {
      position: relative;
      font-size: 18px;
      color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 40px 60px;
      background-color: rgba(0, 0, 0, 0.4);
      .title {
        font-size: 28px;
        font-weight: 300;
      }
      .subtitle {
        font-size: 20px;
      }
      .text {
        font-size: 14px;
        max-width: 450px;
        height: 55px;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
@media screen and (min-width: 720px) {
  .banner-three {
    margin: 40px 0 25px 0;
    height: 300px;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    .member-info {
      width: 190px;
      height: 260px;
      border-radius: 3px;
      overflow: hidden;
      padding-bottom: 10px;
      box-shadow: 10px 7px 12px 0px #8888885e;
      .img-box {
        width: 100%;
        height: 190px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }

      & > p:nth-of-type(1) {
        font-size: 16px;
        margin: 6px 5px;
      }
      & > p:nth-of-type(2) {
        font-size: 13px;
        margin: 4px 5px;
      }
    }
  }
}
</style>
