<template>
  <div
    class="homePageContainer"
    style="overflow: scroll; overflow-x: hidden; overflow-y: hidden"
  >
    <div class="has-view-bg"></div>
    <div class="main-palace">
      <main-nav />

      <!-- 首页banner -->
      <section class="topCarousel">
        <home-banner />
      </section>

      <div class="home-news-panel css_app">
        <div class="news-banner">
          <div class="block">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item
                v-for="(item, index) in Slideimg"
                :key="'id' + index"
              >
                <div class="css_carousel">
                  <img
                    @click="oncar(item)"
                    class="img_css"
                    :src="'https://admin.gdhhsh.cn' + item.path"
                  />
                  <div class="css_tite">
                    <h3 @click="oncar(item)">{{ item.contentTitle }}</h3>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="swiper-pagination-1"></div>
        </div>
        <div class="news-list css_applist">
          <div class="more">
            <div>
              <img src="../assets/home/home-news.png" />
              <span class="css_span">最近新闻</span>
            </div>
            <div class="css_gd" @click.stop="pageJumps('OurNews')">
              <span>更多</span>
              <span>></span>
            </div>
          </div>
          <ul v-for="(item, index) in resdata" :key="index">
            <li class="css_titeapp" @click.stop="pageJump(item)">
              <span class="news-title">{{ item.contentTitle }}</span>
              <span class="detail">></span>
            </li>
          </ul>
        </div>
      </div>

      <div class="home-aboutUs">
        <div data-aos="fade-up" data-aos-duration="5000" data-aos-delay="5000">
          <p class="title">商会简介</p>
          <div class="content">
            广东省湖南怀化商会成立于2012年11月，是由湖南怀化籍在粤人士投资的工商企业自愿发起组成、经怀化市人民政府同意、由广东省社团登记机关登记注册的地方性、非营利性社会组织。
          </div>
          <div class="content">
            为了团结凝聚怀化籍在粤经商经营人士，共同打拼、抱团取暖，践行
            <strong>“怀纳天下，团结互助，化育桑梓，奋发图强”</strong>
            的宗旨，为在粤企业和经营人员建立资源共享、信息互通、互利互惠的平台，经怀化市人民政府积极推动，广东省民政厅批准，于二〇一二年十一月正式注册成立了广东省湖南怀化商会。
          </div>
          <div class="more-btn" @click="briefClick">更多</div>
        </div>
        <!-- <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="4000"></div> -->
      </div>

      <!-- 会员服务 -->
      <section class="home-service">
        <p class="sectionTitle">会员服务</p>

        <div class="service-wrapper">
          <div
            @click="onpush('UnitIntroduce')"
            class="css_shou"
            data-aos="flip-up"
          >
            <div class="imgBox">
              <img src="../assets/home/vip1.jpg" />
            </div>
            <p>法律援助</p>
          </div>
          <div
            @click="onpush('UnitIntroduce')"
            class="css_shou"
            data-aos="flip-down"
          >
            <div class="imgBox">
              <img src="../assets/home/vip2.jpg" />
            </div>
            <p>智慧城市</p>
          </div>
          <div
            @click="onpush('UnitIntroduce')"
            class="css_shou"
            data-aos="flip-up"
          >
            <div class="imgBox">
              <img src="../assets/home/vip3.jpg" />
            </div>
            <p>人力资源</p>
          </div>
          <div
            @click="onpush('UnitIntroduce')"
            class="css_shou"
            data-aos="flip-down"
          >
            <div class="imgBox">
              <img src="../assets/home/vip4.jpg" />
            </div>
            <p>农特产品</p>
          </div>
          <div
            @click="onpush('UnitIntroduce')"
            class="css_shou"
            data-aos="flip-up"
          >
            <div class="imgBox">
              <img src="../assets/home/vip5.jpg" />
            </div>
            <p>品牌设计</p>
          </div>
        </div>
      </section>

      <!-- 商会成员展示 -->
      <section class="home-CCHuaiHua" data-aos="flip-up">
        <p class="sectionTitle">商会成员</p>
        <carousel mode="centered" />
      </section>

      <!-- 商会概况 -->
      <section class="home-overview">
        <p class="sectionTitle">招商合作</p>
        <div class="flex-overview">
          <div class="overview-item">
            <div class="description" data-aos="fade-down-right">
              <p></p>
              <p>资料更新中,敬请期待</p>
            </div>
            <div class="img hidden-xs" data-aos="fade-down-left">
              <img src="../assets/home/memorabilia.png" alt="" />
            </div>
          </div>
          <div class="overview-item">
            <div class="img hidden-xs" data-aos="fade-right">
              <img src="../assets/home/merchants.png" alt="" />
            </div>
            <div class="description" data-aos="fade-left">
              <p>招商项目</p>
              <p>资料更新中,敬请期待</p>
            </div>
          </div>
          <div class="overview-item">
            <div class="description" data-aos="fade-up-right">
              <p>投资项目</p>
              <p>资料更新中,敬请期待</p>
            </div>
            <div class="img hidden-xs" data-aos="fade-up-left">
              <img src="../assets/home/investment.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="home-needToKnow" class="home-needToKnow hidden-sm hidden-xs">
        <!-- <img  src="../assets/needToKnow.png" alt="入会需知"/> -->
        <div class="purpose grand">
          怀纳天下，团结互助，化育桑梓，奋发图强。
        </div>
      </section>

      <back-to-top
        :custom-style="myBackToTopStyle"
        :visibility-height="300"
        :back-position="0"
        transition-name="fade"
      />

      <Footer />
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import MainNav from "@/components/MainNav";
import HomeBanner from "@/components/Banner";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel";
import { newsAPIlist, SlideAPIlist } from "@/api/fetch";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
SwiperCore.use([Autoplay, EffectFade]);
import AOS from "aos";
import "../../node_modules/aos/dist/aos.css";

export default {
  name: "Home",
  data() {
    return {
      resdata: [],
      Slide: [],
      Slideimg: [],
      myBackToTopStyle: {
        right: "50px",
        bottom: "50px",
        width: "6rem",
        height: "6rem",
        "border-radius": "50%",
        "line-height": "45px", // 请保持与高度一致以垂直居中
      },
      swiperOptionsNews: {
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination-1",
          clickable: true,
        },
      },
      isSuccessShow: false,
      isNameShow: false,
      isPhoneShow: false,
      isEmailShow: false,
      isSummaryShow: false,
    };
  },
  components: {
    HomeBanner,
    Carousel,
    Footer,
    MainNav,
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiperNews() {
      return this.$refs.mySwiperNews.$swiper;
    },
  },

  mounted() {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 120,
      once: false,
    });
  },
  created() {
    this.advisoryBtn();
    this.SlideAPI();
  },
  methods: {
    onpush(val) {
      this.$router.push("UnitIntroduce");
    },
    oncar(item) {
      let strItem = JSON.stringify(item.ojbk);
      this.$router.push(
        `/Activity11/` + encodeURIComponent(strItem).replace(/%/g, "%25")
      );
    },
    // 商会简介-更多
    briefClick() {
      this.$router.push({
        name: "Brief",
      });
    },

    //幻灯片接口
    SlideAPI() {
      let obj = {
        categoryId: "1",
        contentType: "f",
        pageSize: 7,
        contentDisplay: "0",
      };
      SlideAPIlist(Qs.stringify(obj)).then((res) => {
        res.data.data.rows.forEach((element) => {
          let arr = JSON.parse(element.contentImg);
          arr.forEach((e) => {
            e.ojbk = element;
            if (element.contentTitle) {
              e.contentTitle = element.contentTitle;
            }
            this.Slideimg.push(e);
          });
        });
      });
    },

    //咨询接口
    advisoryBtn() {
      let obj = {
        categoryId: "1",
        pageSize: 6,
        contentDisplay: "0",
      };
      newsAPIlist(Qs.stringify(obj)).then((res) => {
        res.data.data.rows.forEach((element) => {
          element.contentImg = JSON.parse(element.contentImg);
        });
        this.resdata = res.data.data.rows;
      });
    },
    pageJumps() {
      let id = "1";
      this.$router.push({
        path: `/OurNews/${id}`,
      });
    },
    pageJump(item) {
      if (item == "OurNews") {
        this.$router.push({
          name: "OurNews",
        });
      } else {
        // OurNews
        let strItem = JSON.stringify(item);
        this.$router.push(
          `/Activity11/` + encodeURIComponent(strItem).replace(/%/g, "%25")
        );
      }
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 200px) and (max-width: 768px) {
  .css_carousel {
    .img_css {
      width: 100%;
      height: 77%;
    }
  }
  .home-aboutUs {
    & > div:nth-of-type(1) {
      font: 16px Arial, "微软雅黑";
      position: relative;
      z-index: 5;
      color: #929292;
      // height: 500px;
      // padding: 35px 50px;
      max-width: 1160px;
      margin: auto;
      box-sizing: border-box;
      background: #fff;
      .title {
        font-family: monospace;
        font-size: 3rem;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.067rem;
        text-align: center;
        max-width: 300px;
        margin: 0 auto 10px auto;
        padding-bottom: 6px;
        margin-top: 40px;
      }
      .subTitle {
        font-size: 12px;
        line-height: 30px;
        color: #a5a5a5;
        text-align: center;
      }
      .content {
        line-height: 20px;
        text-indent: 25px;
        margin: 5px auto 4px auto;
        font-family: fantasy;
      }
      .more-btn {
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 60px auto 105px auto;
        border-radius: 2px;
        border: 1px solid #8d8d8d;
        cursor: pointer;
        &:hover {
          background: #fff;
          color: black;
        }
      }
    }
    & > div:nth-of-type(2) {
      z-index: 1;
      width: 100%;
      top: -90px;
      background-color: #e3c7af63;
      height: 220px;
      position: relative;
    }
  }

  .css_carousel .css_tite h3 {
    position: absolute;
    top: 0;
    z-index: 999;
    color: white;
    // opacity: 1;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 365px;
  }
  .css_shouapp {
    display: none !important;
  }
  .css_gd {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
    font-family: none;
  }
  .css_titeapp {
    width: 100vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 20px;
    font-size: 16px;
  }
  .more {
    img {
      display: none;
    }
  }
  .css_span {
    display: flex;
    justify-content: center;
    font-family: monospace;
    font-size: 3rem;
    font-weight: 600;
    color: #333;
    letter-spacing: 0.067rem;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 10px auto;
    padding-bottom: 6px;
  }

  .css_app {
    .css_tite {
      width: 100vw;
      margin-top: -6px;
      height: 55px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /deep/.el-carousel__indicator--horizontal {
      display: none;
    }
  }
}

.css_shou {
  cursor: pointer;
}
.css_carousel .css_tite h3 {
  position: absolute;
  top: 0;
  z-index: 999;
  color: white;
  // opacity: 1;
  font-weight: 500;
}
.css_tite {
  width: 50vw;
  height: 49px;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 251px;
  background: gray;
  opacity: 0.8;
}

// 底部文字
.purpose {
  line-height: 10rem;
  background: #bda09d;
  // opacity: 0.3;
  // color: #5E5E5E;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: serif;
  font-weight: 600;
}

@media screen and (min-width: 720px) {
  .css_carousel {
    .img_css {
      width: 100%;
    }
  }
  .home-aboutUs {
    & > div:nth-of-type(1) {
      font: 16px Arial, "微软雅黑";
      position: relative;
      z-index: 5;
      color: #929292;
      // height: 500px;
      // padding: 35px 50px;
      max-width: 1160px;
      margin: auto;
      box-sizing: border-box;
      background: #fff;
      .title {
        font-family: monospace;
        font-size: 3rem;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.067rem;
        text-align: center;
        max-width: 300px;
        margin: 0 auto 10px auto;
        padding-bottom: 6px;
      }
      .subTitle {
        font-size: 12px;
        line-height: 30px;
        color: #a5a5a5;
        text-align: center;
      }
      .content {
        line-height: 20px;
        text-indent: 25px;
        margin: 5px auto 4px auto;
        font-family: fantasy;
      }
      .more-btn {
        width: 150px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        margin: 60px auto 105px auto;
        border-radius: 2px;
        border: 1px solid #8d8d8d;
        cursor: pointer;
        &:hover {
          background: #fff;
          color: black;
        }
      }
    }
    & > div:nth-of-type(2) {
      z-index: 1;
      width: 100%;
      top: -90px;
      background-color: #e3c7af63;
      height: 220px;
      position: relative;
    }
  }
  .home-service {
    max-width: 1060px;
    margin: 20px auto;
    box-sizing: border-box;
    max-width: 1160px;
    .service-wrapper {
      width: 100%;
      margin: 30px 0;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      div {
        width: 18%;
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        .imgBox {
          width: 100%;
          height: 140px;
          overflow: hidden;
          border-radius: 4px;
          img {
            width: auto;
            height: 100%;
            display: inline-block;
            margin: auto;
          }
        }
        p {
          width: 100%;
          padding: 15px 5%;
          text-align: center;
          font-family: serif;
          font-size: 18px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .home-service {
    max-width: 720px;
    margin: 20px auto;
    box-sizing: border-box;
    max-width: 1160px;
    .service-wrapper {
      width: 100%;
      margin: 30px 0;
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      div {
        width: 18%;
        height: 200px;
        display: flex;
        flex-wrap: wrap;
        .imgBox {
          width: 100%;
          height: 70px;
          overflow: hidden;
          border-radius: 4px;
          img {
            width: auto;
            height: 100%;
            display: inline-block;
            margin: auto;
          }
        }
        p {
          width: 100%;
          padding: 15px 5%;
          text-align: center;
          font-family: serif;
          font-size: 15px;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }
}

.home-overview {
  .flex-overview {
    margin: 20px auto 20px auto;
    .overview-item {
      max-width: 1160px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 50px auto;
      color: #fff;
      .description {
        width: 50%;
        height: 280px;
        padding: 2% 5%;
        box-sizing: border-box;
        background-color: #292929;
        & > p:nth-of-type(1) {
          font-size: 18px;
          font-family: fangsong;
          font-weight: 600;
          margin-bottom: 10px;
        }
        & > p:nth-of-type(2) {
          font-size: 15px;
        }
      }
      .img {
        width: 50%;
        height: 280px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          margin: auto;
        }
      }
      & > div:nth-of-type(1) {
        overflow: hidden;
        border-radius: 12px 0 0 12px;
      }
      & > div:nth-of-type(2) {
        overflow: hidden;
        border-radius: 0 12px 12px 0;
      }
    }
  }
}

.home-CCHuaiHua {
  max-width: 1180px;
}

.home-needToKnow {
  max-width: 1160px;
  border-radius: 3px;
  height: auto;
  width: 1160px;
  overflow: hidden;
  margin: 10px auto 0px auto;
  img {
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }
}
#home-needToKnow {
  margin: 10px auto 0px auto;
}
.el-carousel__item h3 {
  color: black;
  font-size: 18px;
  opacity: 0.75;
  // line-height: 300px;
  // margin-left: 50%;
  // margin-top: 43%;
}

.el-carousel__item:nth-child(2n) {
  // background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: #d3dce6;
}
.css_carousel {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 100%;
}
</style>
