<template>
  <div class="mainBanner-page">
    <!-- PC端超大屏 -->
    <div class="lg-banner visible-lg-block">
      <swiper ref="mySwiperLg" :options="swiperOptionsLg">
        <swiper-slide>
          <div class="title" data-swiper-parallax="-130%">
            <h3></h3>
          </div>
          <div class="img-box">
            <!-- style="transform: translateX(0)" -->
            <img src="@/assets/B1.jpg" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-130%">
            <h3></h3>
          </div>
          <div class="img-box">
            <img src="@/assets/B2.jpg" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-130%">
            <h3></h3>
          </div>
          <div class="img-box">
            <img src="@/assets/B3.jpg" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-130%">
            <h3></h3>
          </div>
          <div class="img-box">
            <img src="@/assets/B4.jpg" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-130%">
            <h3></h3>
          </div>
          <div class="img-box">
            <img src="@/assets/home/banner5.jpg" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="button-next button" @click.stop="slideNext">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
          <g id="arrow-svg-home">
            <g id="circ" class="cls-1">
              <circle cx="42" cy="42" r="40" class="cls-4"></circle>
            </g>
            <g id="arrow">
              <path
                id="arrow-trg"
                d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"
                class="cls-2"
              ></path>
            </g>
            <path id="line" d="M120,0H0" class="cls-3"></path>
          </g>
        </svg>
      </div>
      <div class="button-prev button" @click.stop="slidePrev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 350 160 90">
          <g id="arrow-svg-home">
            <g id="circ" class="cls-1">
              <circle cx="42" cy="42" r="40" class="cls-4"></circle>
            </g>
            <g id="arrow">
              <path
                id="arrow-trg"
                d="M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z"
              ></path>
            </g>
            <path id="line" d="M120,0H0" class="cls-3"></path>
          </g>
        </svg>
      </div>
      <div class="swiper-paginationlg"></div>
    </div>
    <!-- PC商务本\平板中等屏幕 -->
    <div class="md-sm-banner visible-md-block visible-sm-block">
      <swiper ref="mySwiperMdSm" :options="swiperOptionsMdSm">
        <swiper-slide>
          <img
            style="width: 100%; display: inline-block"
            src="@/assets/B1.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            style="width: 100%; display: inline-block"
            src="@/assets/B2.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            style="width: 100%; display: inline-block"
            src="@/assets/B3.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            style="width: 100%; display: inline-block"
            src="@/assets/B4.jpg"
            alt=""
          />
        </swiper-slide>
      </swiper>
      <!-- Add Navigation -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
      <!-- Add Scrollbar -->
      <div class="swiper-scrollbar"></div>
    </div>
    <!-- 移动端超小屏幕 -->
    <div class="xs-banner visible-xs-block" :style="{ height: '20vh' }">
      <swiper
        ref="mySwiperXs"
        :options="swiperOptionsXs"
        :style="{ height: '20vh' }"
      >
        <swiper-slide>
          <img
            style="width: 100%; height: 100%; display: inline-block"
            src="@/assets/B1.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            style="width: 100%; height: 100%; display: inline-block"
            src="@/assets/B2.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            style="width: 100%; height: 100%; display: inline-block"
            src="@/assets/B3.jpg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide>
          <img
            style="width: 100%; height: 100%; display: inline-block"
            src="@/assets/B4.jpg"
            alt=""
          />
        </swiper-slide>

        <swiper-slide>
          <img
            style="width: 100%; height: 100%; display: inline-block"
            src="@/assets/home/banner5.jpg"
            alt=""
          />
        </swiper-slide>
      </swiper>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import "swiper/swiper-bundle.min.css"; //导入样式
import { Swiper, SwiperSlide } from "vue-awesome-swiper"; //使用Swiper6包装好的组件
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper"; //使用swiper6的模组扩展
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar]); //注册模块

export default {
  data() {
    return {
      clientHeight_: window.innerHeight,
      lock: false, //标记锁
      bgColor: [
        "rgb(179, 189, 196)",
        "rgb(180, 183, 166)",
        "rgb(140, 152, 187)",
      ], //背景色
      swiperOptionsLg: {
        autoplay: {
          delay: 5000,
        },
        width: window.innerWidth,
        height: window.innerHeight,
        speed: 1300,
        allowTouchMove: false, //禁止触摸滑动
        parallax: true, //文字位移视差
        pagination: {
          el: ".swiper-paginationlg",
          clickable: true,
        },
      },
      swiperOptionsMdSm: {
        width: window.innerWidth,
        height: 800,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionsXs: {
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiperLg() {
      return this.$refs.mySwiperLg.$swiper;
    },
    swiperMdSm() {
      return this.$refs.mySwiperMdSm.$swiper;
    },
    swiperXs() {
      return this.$refs.mySwiperXs.$swiper;
    },
  },
  mounted() {
    this.swiperMdSm.scrollbar.$el.css("height", "5px");
  },
  methods: {
    //Lg-Banner不使用自带的按钮组件，使用lock标记按钮是否被锁定
    slideNext() {
      this.swiperLg.slideNext();
    },
    slidePrev() {
      this.swiperLg.slidePrev();
    },
  },
};
</script>
<style lang="less" scoped>
.swiper-container {
  height: 100%;
  position: relative; //去掉这个有问题吗
}
.lg-banner {
  height: 520px;
  background: #eee;
  font-family: sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  position: relative;
  /deep/.swiper-paginationlg {
    position: relative;
    bottom: 30px;
    width: 100%;
    text-align: center;
    z-index: 10;
    .swiper-pagination-bullet {
      width: 38px;
      height: 5px;
      display: inline-block;
      border-radius: 5px;
      margin: 3px 5px;
      background: #f8f6f6e6;
      opacity: 0.2;
    }
    .swiper-pagination-bullet-active {
      background: #43a0ece6;
      opacity: 1;
    }
  }
  .swiper-wrapper {
    transition-delay: 1s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .title {
    transition-delay: 1s;
    z-index: 10;
  }
  .title h3 {
    font-weight: 500;
    font-size: calc(55px + 54 * ((53vw + 53vh) - 520px) / 820);
    letter-spacing: 5px;
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 2px #fff;
  }
  .img-box {
    width: 100%;
    height: 100%;
    position: absolute;
    // transform: scale(0.6, 0.6);
    // transition-duration: 1s;
    // transition-property: transform;
    // transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    opacity: 0.9;
    overflow: hidden;
  }
  .img-box img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
    // transform: scale(1.2, 1.2) translateX(50%);
    // transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
    // transition-property: transform;
  }
  .button-prev,
  .button-next {
    transition: 0.5s;
    outline: none;
    position: absolute;
    width: 140px;
    z-index: 10;
    top: 50%;
    transform: translateY(-34px);
    cursor: pointer;
  }
  .button-prev {
    left: 5vw;
  }
  .button-next {
    right: 5vw;
  }
  .button.disabled {
    opacity: 0.2;
    cursor: default;
  }
  #arrow-svg-home {
    transform: translateY(353px);
  }
  .button-next #arrow-svg-home {
    transform: translateY(353px) rotateY(180deg);
    transform-origin: 80px 0px 0px;
  }
  svg {
    transition: 0.5s;
  }
  .cls-1 {
    transition: 0.5s;
    opacity: 0.4;
    transform-origin: -20px 40px;
    opacity: 1;
  }
  .cls-4 {
    transition: 0.5s;
    stroke-width: 2px;
    stroke: #fff;
    fill: none;
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    opacity: 0.4;
    transform-origin: 0px 0px 0px;
  }
  #arrow-trg {
    transition: 0.5s;
    fill: #fff;
    transform: rotateY(180deg) translate(-53px, 39px);
  }
  #line {
    transition: 0.5s;
    stroke: #fff;
    transform: translate(50px, 42px);
  }
  .button-prev:not(.disabled):hover svg {
    transform: translateX(-25px);
  }
  .button-next:not(.disabled):hover svg {
    transform: translateX(25px);
  }
  .button:not(.disabled):hover .cls-1 {
    transform: scale(1.1);
  }
  .button:not(.disabled):hover .cls-4 {
    stroke-dasharray: 2px;
    stroke-dashoffset: 2px;
    opacity: 1;
  }
  .button:not(.disabled):hover #arrow-trg {
    transform: rotateY(180deg) translate(-37px, 39px);
  }
  .button:not(.disabled):hover #line {
    transform: translate(35px, 42px) scaleX(0.33);
  }
}

.md-sm-banner {
  height: 100%;
  background: #eee;
  font-family: sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 100%;
    background-color: rgb(179, 189, 196);
    transition: 1s background-color 1.3s;
    .swiper-wrapper {
      transition-delay: 1s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        .title {
          transition-delay: 1s;
          z-index: 10;
        }
        .title h3 {
          font-weight: 700;
          font-size: calc(55px + 54 * ((53vw + 53vh) - 520px) / 820);
          letter-spacing: -1px;
          color: rgba(255, 255, 255, 0);
          -webkit-text-stroke: 2px #fff;
        }
        .img-box {
          width: 100%;
          height: 100%;
          position: absolute;
          transform: scale(0.6, 0.6);
          transition-duration: 1s;
          transition-property: transform;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          opacity: 0.9;
          overflow: hidden;
        }
        .img-box img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.2, 1.2) translateX(50%);
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          transition-property: transform;
        }
        .button-prev,
        .button-next {
          transition: 0.5s;
          outline: none;
          position: absolute;
          width: 140px;
          z-index: 10;
          top: 45vh;
          transform: translateY(-34px);
          cursor: pointer;
        }
        .button-prev {
          left: 5vw;
        }
        .button-next {
          right: 10vw;
        }
        .button.disabled {
          opacity: 0.2;
          cursor: default;
        }
        #arrow-svg-home {
          transform: translateY(353px);
        }
        .button-next #arrow-svg-home {
          transform: translateY(353px) rotateY(180deg);
          transform-origin: 80px 0px 0px;
        }
        svg {
          transition: 0.5s;
        }
        .cls-1 {
          transition: 0.5s;
          opacity: 0.4;
          transform-origin: -20px 40px;
          opacity: 1;
        }
        .cls-4 {
          transition: 0.5s;
          stroke-width: 2px;
          stroke: #fff;
          fill: none;
          stroke-dasharray: 1;
          stroke-dashoffset: 1;
          opacity: 0.4;
          transform-origin: 0px 0px 0px;
        }
        #arrow-trg {
          transition: 0.5s;
          fill: #fff;
          transform: rotateY(180deg) translate(-53px, 39px);
        }
        #line {
          transition: 0.5s;
          stroke: #fff;
          transform: translate(50px, 42px);
        }
        .button-prev:not(.disabled):hover svg {
          transform: translateX(-25px);
        }
        .button-next:not(.disabled):hover svg {
          transform: translateX(25px);
        }
        .button:not(.disabled):hover .cls-1 {
          transform: scale(1.1);
        }
        .button:not(.disabled):hover .cls-4 {
          stroke-dasharray: 2px;
          stroke-dashoffset: 2px;
          opacity: 1;
        }
        .button:not(.disabled):hover #arrow-trg {
          transform: rotateY(180deg) translate(-37px, 39px);
        }
        .button:not(.disabled):hover #line {
          transform: translate(35px, 42px) scaleX(0.33);
        }
      }
    }
  }
}

.xs-banner {
  position: relative;
  /deep/.swiper-pagination {
    width: 100%;
    bottom: 3.5px;
    .swiper-pagination-bullet {
      width: 22px;
      height: 4px;
      border-radius: 8px;
      margin: 0 3.5px;
    }
  }
}
</style>
