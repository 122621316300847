<template>
  <div class="row none-spacing">
    <div class="col-lg-7 col-md-7 col-sm-7 none-spacing">
      <img :src="src" alt="" />
    </div>
    <div class="txtArea col-lg-5 col-md-5 col-sm-5 none-spacing">
      <div>{{ title }}</div>
      <div>
        {{ txtarea }}
      </div>
      <div class="tryOutBtn" @click="toRegister">立即体验</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    swiperType:{
      type: String,
      default: "horizontal",
    },
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "优制云",
    },
    txtarea: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    toRegister() {
      this.$router.push({
        name: "Register",
      });
    },
  },
};
</script>

<style scoped lang="less">
.txtArea > div:nth-of-type(1) {
  font-size: 3rem;
  font-weight: 400;
  margin-top: 25px;
  color: #333;
}
.txtArea > div:nth-of-type(2) {
  margin-top: 1.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 22px;
  color: #666;
  height: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.tryOutBtn {
  margin-top: 5%;
  width: 12rem;
  height: 3.3rem;
  line-height: 3.3rem;
  text-align: center;
  border-radius: 2.1rem;
  border: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
img {
  width: 90%;
  border-radius: 3px;
}
</style>
